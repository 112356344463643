(<template>
  <div :class="{'in-progress': progressActive,
                'one-assignment--full-width': $isGodmode(),
                'one-assignment--blocked': showBlockedByAvailabilityBlock}"
       class="one-assignment is-progress-bar project-print">
    <printed-info job-type="oneproject" />
    <action-buttons :config="actionButtonsConfig"
                    class="one-assignment__action-buttons" />
    <projects-breadcrumbs />
    <div class="one-assignment__main-wrapper">
      <section v-show="!showMobileChat"
               class="one-assignment__info-cont one-assignment__info-cont--right">
        <info :show-edit-button="false"
              :progress="projectInfoProgress"
              class="one-assignment__info"
              @fetchsuitableinterpreters="fetchSuitableInterpreters" />
      </section>
      <div v-show="!showMobileChat"
           class="project__interactive-area-wrapper one-assignment__interactive-area-wrapper">
        <div class="one-assignment__interactive-area">
          <!-- -- Project Subtasks -- -->
          <template v-if="userIsInterpreter">
            <single-project-task v-for="(task, index) in project.tasks"
                                 :key="task.publicId + index"
                                 :source-language-id="project.sourceLanguageId"
                                 :initially-opened="index === 0"
                                 :task="task" />
          </template>

          <!-- JOB SHORTCUTS FOR MULTIPLE BOOKING -->
          <job-shortcuts v-if="isSeriesJob" />

          <!-- BLOCKS UNDER PROJECT TITLE -->
          <project-title v-if="userIsNotInterpreter"
                         class="one-assignment__inner-section">
            <assigned-project-translator v-if="hasTasks" />
          </project-title>

          <!-- FEEDBACK BLOCK -->
          <template v-if="showFeedbackSection">
            <pt-feedback class="one-assignment__inner-section" />
            <invoice-info-section
              class="one-assignment__feedback-invoice"
              location="project_feedback" />
          </template>

          <!-- Chat with Project Manager -->
          <!--          <template v-if="userIsNotInterpreter">-->
          <!--            <project-chat />-->
          <!--          </template>-->
        </div>
        <div v-if="showFinishedBlock && $isGodmode() && jobStatementId"
             class="one-assignment__confirmation-area">
          <decision-admin-tools class="one-assignment__inner-section" />
        </div>
      </div>
      <!-- Right Section Admin Tool Block -->
      <div v-if="$isGodmode()"
           class="one-assignment__info-cont one-assignment__info-cont--left one-assignment__info-cont--dark">
        <info :progress="progressActive"
              :show-admin-section-only="true"
              class="one-assignment__info" />
      </div>
      <magic-links-handler v-if="jobStatus" />
      <printed-footer />
    </div>
  </div>
</template>)

<script>
  import {mapGetters, mapState} from 'vuex';
  import helpers from '@/helpers';
  import ApplyPrototype from '@/prototypes/one_assignment_page/ApplyPrototype';

  export default {
    asyncData({store, route}) {
      const projectID = route.params.id;
      let promisesArr = '';

      store.dispatch('PostingNotifierStore/setIsTranslation', true);
      // requests for buyers
      if (store.getters['UserInfoStore/userIsNotInterpreter']) {
        promisesArr = [...promisesArr, store.dispatch('PostingNotifierStore/fetchMainData').then(() => {
          // preselect invoice section
          store.dispatch('OneProjectStore/preSelectInvoiceSection');
        })];
      }

      if (!store.state.OneProjectStore.project || store.state.OneProjectStore.project.id != projectID) {
        promisesArr = [...promisesArr, store.dispatch('OneProjectStore/getProjectById', projectID).then((data) => {
          if (store.getters['UserInfoStore/userIsNotInterpreter'] && data?.order?.ticketId) {
            promisesArr = [...promisesArr, store.dispatch('OneTicketStore/getTicket', data?.order?.ticketId)];
          }
        })];
      }
      return promisesArr ? Promise.all(promisesArr) : promisesArr;
    },
    metaInfo() {
      const storeJobObj = this.$store.state.OneAssignmentStore.storeJobObj;
      const jobId = storeJobObj ? helpers.getJobInfo.jobId(storeJobObj) : this.$route.params.id;

      return {title: `#${jobId} - ${this.$getString('metaData', 'longTitle')}`};
    },
    components: {
      info: () => import('@/components/project_components/one_project/project_info/ProjectInfo'),
      'action-buttons': () => import('@/components/project_components/one_project/project_info/ProjectActionButtons'),
      'magic-links-handler': () => import('@/components/assignment_components/one_assignment/MagicLinkHandler'),
      'job-shortcuts': () => import('@/components/assignment_components/one_assignment/interactive_info/JobShortcutsWrapper'),
      'projects-breadcrumbs': () => import('@/components/project_components/one_project/ProjectsBreadcrumbs'),
      'project-title': () => import('@/components/project_components/one_project/interactive_info/ProjectTitle'),
      'assigned-project-translator': () => import('@/components/project_components/one_project/shared_components/AssignedProjectTranslator'),
      // 'project-chat': () => import('@/components/project_components/one_project/interactive_info/ProjectChat'),
      'single-project-task': () => import('@/components/project_components/one_project/SingleProjectTask'),
      // FEEDBACK SECTIONS
      'invoice-info-section': () => import('@/components/assignment_components/one_assignment/interactive_info/pt_feedback/feedback_sections/InvoiceInfoSection'),
      'pt-feedback': () => import('@/components/project_components/one_project/interactive_info/ProjectFeedback'),
      // FINISHED BLOCKED SECTIONS
      'decision-admin-tools': () => import('@/components/assignment_components/one_assignment/interactive_info/pt_finished/admin_tools/DecisionAdminTools'),
      // PRINT COMPONENTS
      'printed-info': () => import('@/components/assignment_components/one_assignment/AssignmentPrintInfo'),
      'printed-footer': () => import('@/components/assignment_components/one_assignment/AssignmentPrintFooter'),
    },
    extends: ApplyPrototype,
    data() {
      return {
        projectInfoProgress: false
      };
    },
    computed: {
      ...mapGetters('UserInfoStore', [
        'userIsNotInterpreter',
        'userIsInterpreter',
        'userCanUseChat'
      ]),
      ...mapGetters('OneAssignmentStore', ['isSeriesJob']),
      ...mapGetters('OneProjectStore', ['project']),
      ...mapState('AssignmentFlowStore', ['invoiceGenerationInProgress']),
      ...mapState('OneAssignmentStore', {
        activeMobileChat: (state) => state.mobileChatOpened,
        progressActive: (state) => state.progressActive,
        mobileInteractionContentOpened: (state) => state.mobileInteractionContentOpened,
        assignmentObj: (state) => state.storeJobObj || {},
        interDiscussion: ({chatDiscussions}) => chatDiscussions?.discussion || {}
      }),
      interDiscussionStatus() { return this.interDiscussion.status || ''; },
      jobStatus() { return this.assignmentObj.status || ''; },
      assignmentRequirements() { return this.assignmentObj.interpretationRequirement || {}; },
      jobType() { return this.assignmentRequirements.jobType || ''; },
      jobStatementId() { return this.assignmentObj.statementId || ''; },
      // JOB STATUSES
      isSuitableProject() { return this.project.isSuitable; },
      isUnsuitableJob() { return this.jobStatus === 'unsuitable'; },
      isCancelledJob() { return this.jobStatus === 'cancelled'; },
      isFinishedJob() { return this.jobStatus === 'finished'; },
      isAcceptedJob() { return this.jobStatus === 'accepted'; },
      isInProgressJob() { return this.jobStatus === 'in_progress'; },
      isPublishedJob() { return this.jobStatus === 'published'; },
      isAppliedJob() { return this.jobStatus === 'applied'; },
      isClosedJob() { return this.jobStatus === 'closed'; },
      isRejectedJob() { return this.jobStatus === 'rejected'; },
      isFeedbackJob() { return this.jobStatus === 'needsFeedback'; },
      isFinishedProject() { return this.project.status === 'finished'; },
      isCompletedProject() { return this.project.status === 'completed'; },
      showFeedbackSection() {
        return (this.isFinishedProject || this.isCompletedProject) && this.userIsNotInterpreter;
      },
      hasTasks() { return this.project.tasks && this.project.tasks.length > 0; },
      // DISCUSSIONS STATUSES
      isRejectedDiscussion() { return this.interDiscussionStatus === 'rejected'; },
      // JOB ACCESSIBILITY
      isPrivateJob() { return this.assignmentObj.accessibility === 'private'; },
      isPublicJob() { return this.assignmentObj.accessibility === 'public'; },
      // OTHER
      isBlockedByAvailabilityJob() { return this.interDiscussion.blockedByAvailability; },
      showBlockedByAvailabilityBlock() {
        return this.isBlockedByAvailabilityJob
          && !this.isCancelledJob
          && !this.isInProgressJob
          && !this.isAcceptedJob
          && !this.isFinishedJob
          && !this.isFeedbackJob;
      },
      userCanSeeChat() { return this.userIsNotInterpreter && !this.$isGodmode() ? this.userCanUseChat : !this.showSelfSummaryBlock; },
      showNoChatSection() { return !this.userCanSeeChat && this.userIsNotInterpreter; },
      showChatSection() { return this.userCanSeeChat && !this.showBlockedByAvailabilityBlock; },
      showJobChat() {
        if (this.userIsNotInterpreter) return this.clientWidth > 1024 || this.activeMobileChat;
        return this.clientWidth > 767 || this.activeMobileChat;
      },
      showMobileChat() {
        if (this.userIsNotInterpreter) return this.clientWidth < 1025 && this.activeMobileChat;
        return this.clientWidth < 768 && this.activeMobileChat;
      },
      showChatAccordion() {
        return this.showChatSection
          && (this.userIsNotInterpreter
            || this.showJobChat);
      },
      clientWidth() { return this.$store.state.GlobalStore.clientWidth; },
      isThinChat() {
        return this.isInProgressJob
          || this.isAcceptedJob
          || this.isFinishedJob
          || this.isFeedbackJob;
      },
      isMobInteractionBlockOpened() { return this.userIsInterpreter && this.mobileInteractionContentOpened && this.clientWidth < 768; },
      finishTimePassed() { return this.$moment().isAfter(this.assignmentRequirements.finishTime); },
      jobLocked() { return this.assignmentObj.locked || false; },
      showFeedbackBlock() {
        return this.isFeedbackJob
          || (this.isFinishedJob && !this.jobLocked);
      },
      showFinishedBlock() {
        return this.isFinishedJob
          && this.jobLocked;
      },
      isNewAssignment() {
        return this.userIsNotInterpreter
          && !this.finishTimePassed
          && !this.isAcceptedJob
          && !this.isFeedbackJob
          && !this.isCancelledJob
          && !this.isFinishedJob
          && !this.isClosedJob
          && this.isPublicJob;
      },
      isTimePassedJob() {
        return this.userIsNotInterpreter
          && !this.isAcceptedJob
          && !this.isInProgressJob
          && !this.isFeedbackJob
          && !this.isCancelledJob
          && !this.isFinishedJob
          && this.finishTimePassed;
      },
      isNotStartedJob() {
        return !this.isCancelledJob
          && !this.isInProgressJob
          && !this.isClosedJob
          && !this.isFinishedJob
          && !this.isFeedbackJob;
      },
      isRejectedState() {
        return this.userIsInterpreter
          && !this.isUnsuitableJob
          && (this.isRejectedDiscussion
            || this.isRejectedJob);
      },
      showFaiBlock() {
        return this.userIsNotInterpreter
          && (this.$isGodmode()
            || ((this.isPublishedJob || this.isAppliedJob)
              && this.isPrivateJob
              && !this.finishTimePassed));
      },
      showAwardedBlock() {
        const showAwardedBlock = this.isAcceptedJob || this.isInProgressJob;
        const showAwardedBlockForFinishedJob = this.userIsNotInterpreter && (this.isFinishedJob || this.isFeedbackJob);

        return showAwardedBlock || showAwardedBlockForFinishedJob;
      },
      isBeforeProjectAccepted() {
        return this.project.status == 'quote_requested' || this.project.status == 'quote_sent' || this.project.status == 'quote_rejected';
      },
      actionButtonsConfig() {
        const showCopyBtn = this.userIsNotInterpreter;
        const showCancelBtn = this.userIsNotInterpreter && this.isBeforeProjectAccepted;

        return {
          showCopyBtn,
          showCancelBtn
        };
      }
    },
    watch: {
      invoiceGenerationInProgress() {
        if (this.invoiceGenerationInProgress) {
          setTimeout(() => {
            this.$store.commit('InfoModalStore/setInfoModal', {
              text: this.$pgettext('translation', 'Final invoice generation is in progress. Please load this page again in 5 minutes.')
            });
          });
        }
      }
    },
    methods: {
      fetchSuitableInterpreters() {
        this.$refs.fai.fetchInterpreters();
      },
      openUnsuitableJobModal() {
        this.$store.commit('ModalStore/setModal', {
          component: 'unsuitable-job-modal',
          classes: ['unsuitable-modal'],
          data: {
            title: this.$pgettext('translation', 'This assignment is no longer available'),
            location: 'project'
          },
          disableClose: true,
          width: 410
        });
      },
      fetchInterpreters() {
        const showInvitationQueue = this.$store.state.FAIFiltersStore.showInvitationQueue;

        const promise = showInvitationQueue
          ? this.getAutoInvitableInterpreters({jobId: this.jobId})
          : this.getSuitableInterpretersIds({jobId: this.jobId});

        this.progressActive = true;
        this.clearSuitableInterpreters();
        promise.then(() => {
          this.getSuitableInterpreters();
        });
      },
      accordionStateChanged(accordionOpened) {
        if (accordionOpened) this.fetchInterpreters();
      },
    },
    mounted() {
      if (this.userIsInterpreter) {
        // for we do page load we have no dataPromise returning
        if (this.isSuitableProject === false) this.openUnsuitableJobModal();

        // normal navigation when the ssr page is mounted and we have promise return
        if (this.dataPromise) {
          this.projectInfoProgress = true;
          this.dataPromise.then(() => {
            this.projectInfoProgress = false;
            if (!this.isSuitableProject) this.openUnsuitableJobModal();
          });
        }
      }
    },
    beforeDestroy() {
      this.$store.commit('OneAssignmentStore/clearAssignmentStore');
      this.$store.commit('AssignmentFlowStore/clearFlowData');
    },
    beforeRouteLeave(to, from, next) {
      this.$store.dispatch('PostTranslationProjectStore/clearStore');
      this.$store.commit('FeedbackStore/clearStore');
      this.$store.commit('s_DashboardStore/clearFeedbackJobs');
      this.$store.commit('PricingTemplatesStore/clearStore');
      this.$store.commit('FAIListStore/clearStore');
      this.$store.commit('FAIFiltersStore/clearStore');
      this.$store.commit('OneProjectStore/clearStore');
      this.$store.commit('PostingNotifierStore/clearStore');
      next();
    }
  };
</script>

<style src="@assets/css/default_accordion.css"></style>

<style scoped>
.one-assignment {
  position: relative;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  max-width: 100%;
  margin: 0 auto;
  padding: 20px 20px 20px;
}

.one-assignment__main-wrapper {
  display: flex;
  align-items: flex-start;
}

.one-assignment__action-buttons {
  width: 100%;
  margin-bottom: 15px;
}

.one-assignment__info-cont {
  display: block;
  overflow: auto;
  width: 300px;
  min-width: 300px;
}

.one-assignment__info-cont--right {
  padding-right: 10px;
}

.one-assignment__info-cont--left {
  padding-left: 10px;
}

.one-assignment__interactive-area-wrapper {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  overflow: auto;
}

.one-assignment__interactive-area {
  width: 606px;
  max-width: 100%;
}

.one-assignment--full-width {
  width: 100%;
}

.one-assignment--full-width .one-assignment__interactive-area {
  width: 100%;
}

.one-assignment__confirmation-area,
.one-assignment__feedback-invoice {
  max-width: 1230px;
  margin-top: 10px;
}

.feedback__section {
  margin-bottom: 10px;
}

.one-assignment__chat-content {
  display: flex;
  flex-grow: 1;
  height: 30%;
}

.one-assignment__chat-accordion {
  display: flex;
  flex-direction: column;
  height: 30%;
}

.one-assignment__inner-section {
  margin-bottom: 10px;
}

.one-assignment__admin-chat-content {
  height: 330px;
}

.one-assignment__chat-content.one-assignment__chat-wrapper--thin {
  height: 30%;
}

@media (max-width: 1024px) {
  .one-assignment__chat-content.one-assignment__chat-wrapper--thin {
    height: 20%;
  }

  .one-assignment__interactive-info-wrapper--travel-rates {
    overflow: auto;
  }
}

@media (max-width: 767px) {
  .one-assignment {
    padding: 10px 0 60px;
  }

  .one-assignment__main-wrapper {
    display: block;
    height: initial;
  }

  .one-assignment__interactive-area {
    width: 100%;
  }

  .one-assignment__action-buttons {
    padding: 0 15px;
  }

  .one-assignment__chat-content {
    min-height: initial;
  }

  .one-assignment__interactive-info-wrapper {
    max-height: initial;
    margin-top: 10px;
    margin-bottom: 10px !important;
    padding: 0;
  }

  .one-assignment__info-cont {
    overflow: initial;
    width: 100%;
    padding-right: 0;
    padding-left: 0;
  }

  .one-assignment__info-cont--left {
    margin-top: 10px;
  }

  .one-assignment__interactive-area-wrapper {
    width: 100%;
    height: initial;
  }

  .one-assignment--blocked {
    background-color: white;
  }

  .one-assignment--blocked .one-assignment__interactive-info-wrapper {
    padding: 0;
  }
}
</style>
